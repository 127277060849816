.publications-text {
  color: black;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.publication-title-link-hover{
  color: black;
}

.publication-title-link-hover:hover{
  color: #ea4f4b;
}