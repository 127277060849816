.imgPerson {
  width: 90%;
  //padding: 2rem;
  //align-items: flex-start;
  //height: 100%;
  //display: inline;
  border-radius: 50%;
}

.name {
  margin-top: 3rem;
  font-family: "Helvetica", sans-serif;
  font-size: small;
  color: #545e69;
  //font-weight: bold;
}

.smallText {
  color: rgb(119, 132, 146);
  font-family: Lato, arial, sans-serif;
  //font-weight: lighter;
  font-size: small;
}

.bio {
  color: #ea4f4b;
  font-size: small;
  margin: auto;
  padding-left: 5rem;
  padding-right: 5rem;
  //font-family: Lato, arial, sans-serif;
  //font-weight: lighter;
  //text-align: center;
  //padding-left: 9rem;
}

.quote {
  color: rgb(119, 132, 146);
  //margin: 10% 0 0 0;
}

.level-text {
  color: #62788d;
  font-weight: bold;
  font-size: large;
  display: inline-flex;
}
.level-text:hover {
  color: #ea4f4b;
}

.socials {
  position: relative;
  transition: transform 0.3s ease;
  //transform: translateX(0px);
}
@keyframes animation {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
.socials:hover {
  //transform: translateX(10px);
  animation-name: animation;
  animation-duration: 2s;
  color: #fdb414;
}

.icon {
  //color: #ea4f4b ;
  color: #545e69;
}

.ab {
  color: #545e69;
  font-size: large;
  font-weight: bold;
  @media screen and (min-width: 1000px) and (max-width: 1500px){
      padding-left: 11rem;


  }
  @media screen and (min-width: 1500px){
      padding-left: 27rem;
}}


.ab:hover {
  color: #fdb414;
  font-weight: normal;
  border: 2px;
  font-size: medium;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.downloadCVButton {
  background: #fdb414;
  border: white;
}
.downloadCVButton:hover {
  background: rgb(119, 132, 146);
}


.bigS-screen-padding {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (min-width: 1000px) and (max-width: 1500px){
    margin-left: auto;


  }
  @media screen and (min-width: 1500px){
    margin-left: auto;
  }
}