
.centeredContainer {
  max-width: 120rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  //display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 1200px) {
    flex-direction: row; /* On very big screens, switch back to row layout */
    justify-content: space-between; /* Spread items evenly across the container */
  }
}
.Home {
  .welcome-container {
    padding-top: 5rem;
    padding-bottom: 3rem;
  }

  .justify-text {
    text-align: justify;
  }

  .button {
    margin: 15px 15px 0px 0px;
  }

  .centerImg {
    text-align: center;
  }
}
@media screen and (max-width: 769px){
  .tile:not(.is-child) {
    padding-top: 3rem;
  }
}
@media screen and (min-width: 769px) and (max-width: 1000px) {
  .tile:not(.is-child) {
    padding-top: 3rem;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1500px){
  .tile:not(.is-child) {
    padding-top: 3rem;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
@media screen and (min-width: 1500px){
  .tile:not(.is-child) {
    padding-top: 3rem;
    padding-left: 14rem;
    padding-right: 14rem;
  }
}