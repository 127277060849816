.imgCompany {
  object-fit: cover;
  /*width: 64px;*/
  /*height: 64px;*/
  margin-top: 1.5rem;
  object-position: center;
  margin-left: 0;
}

.job-title {
  font-size: larger;
  font-weight: bold;
  color: black;
  font-family: Helvetica, sans-serif;
  margin: 1.5rem;
}
.job-content {
  color: black;
  font-family: Helvetica, sans-serif;
  margin: 1.5rem;
  text-align: justify;
  text-justify: inter-word;
}

.text-desc {
  color: rgb(119, 132, 146);
  font-size: small;
  text-align: justify;
  text-justify: inter-word;
  /*margin top right bottom left*/
  margin: 1rem 1.5rem 0 0;
}
.media-adjustment {
  text-align: justify;
  text-justify: inter-word;
  word-wrap: break-word;
}
.text-adjustment {
  text-align: justify;
  text-justify: inter-word;
  /*margin-right:2.5rem;*/
  justify-content: center;
  word-wrap: break-word;
  width: 100%;
}
.featured-image {
  opacity: 1;
  display: block;
  /*height: auto;*/
  transition: 0.5s ease;
  backface-visibility: hidden;
  /*filter: grayscale(100%);*/
  /*-webkit-filter: grayscale(100%);*/
}

.featured-image:hover ~ #hoverText {
  display: block;
  color: black;
  position: absolute;
  background: white;
  opacity: 0.5;
}
.middle {
  transition: 0.5s ease;
  opacity: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.text {
  /*background-color: #ea4f4b;*/
  /*background-color: #545e69;*/
  /*opacity: 80%;*/
  color: #62788d;
  font-size: large;
  /*padding: 16px 32px;*/
  font-weight: bold;
  /*text-decoration: underline;*/
  text-align: left;
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
  /*align-items: center;*/
  /*text-align: center;*/
}
.container {
  position: relative;
  /*width: 50%;*/
}

.feature-container:hover .image {
  opacity: 0.5;
}

.feature-container:hover .middle {
  opacity: 1;
}

.link-reverse {
  color: black;
}
.link-reverse:hover {
  color: #ea4f4b;
}

