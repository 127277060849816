.pagination {
  justify-content: center;
  display: flex;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  /*display: inline-block;*/
  width: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  font-size: small;
  margin: 0.1rem 0.5rem;
}

ul.pagination li a {
  text-decoration: none;
  color: #545e69;
  font-size: 20px;
}

ul.pagination li.active a {
  color: white;
  /*background-color: #337ab7;*/
  height: max-content;
}
ul.pagination li.active {
  /*background-color: #337ab7;*/
  height: max-content;
}

ul.pagination li a:hover,
ul.pagination li a.active {
  color: black;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
