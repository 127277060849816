.level-title {
  font-family: Helvetica, sans-serif;
}

.level-key {
  font-size: larger;
  color: black;
}

.level-value {
  color: rgb(119, 132, 146);
  font-size: medium;
}
.star {
  color: #ea4f4b;
}
