.skillset {
  /*margin: 1.5rem;*/
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  flex-wrap: wrap;
}
.level-title {
  font-family: Helvetica, sans-serif;
  color: rgb(119, 132, 146);
  font-weight: bold;
}

.ul-text {
  color: black;
}

@media only screen and (max-width: 1000px) {
  .level-item-skill {
    flex-flow: row wrap;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    flex-wrap: wrap;
  }
}
