@charset "utf-8";

// Set your brand colors
$yellow: #fdb414;
$pink: #fa7c91;
$brown: #ea4f4b;
$beige-light: #d0d1cd;
$beige-lighter: #ffffff;
$red: #981b1e;
$green: #178a43;
$light-blue: #c2d7e6;
$dark-blue: #62788d;
$blue: #3e8ed0ff;
$white: white;
$light-blue-again: #f1f5f9ff;
// Update Bulma's global variables
$family-sans-serif: "Open+Sans", sans-serif;
//$grey-dark: $brown;
$grey-dark: $blue;
//$grey-light: $beige-light;
$grey-light: $white;
//$primary: $brown;
$primary: $blue;
//$link: $grey-dark;
$link: $blue;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
//$body-background-color: $beige-lighter;
$body-background-color: $light-blue-again;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

$navbar-item-hover-background-color: #778492;
$subtitle-color: rgb(119, 132, 146);
$title-color: $brown;
$title-color: rgb(84, 94, 105);
$navbar-item-hover-color: white;
$navbar-item-color: rgb(84, 94, 105);

// Import only what you need from Bulma
@import "../node_modules/bulma/bulma.sass";
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/elements/form.sass";
@import "../node_modules/bulma/sass/elements/title.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";
@import "../node_modules/bulma/sass/layout/hero.sass";
@import "../node_modules/bulma/sass/layout/section.sass";
@import "../node_modules/bulma/sass/utilities/mixins.sass";
@import "../node_modules/bulma/sass/components/pagination.sass";
