/* Slideshow container */
.slideshow-container {
  align-items: center;
  position: relative;
}

/* Slides */
.mySlides {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 6rem;
  /*height: 93vh;*/
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -30px;
  padding: 16px;
  color: #888;
  font-weight: bold;
  font-size: 2vw;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  position: absolute;
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

/* The dot/bullet/indicator container */
.dot-container {
  text-align: center;
  padding: 20px;
  /*background:#62788d;*/
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 8px;
  background-color: #333;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

/* Add a background color to the active dot/circle */
.active {
  background-color: #888;
  height: 17px;
  width: 17px;
}
.dot:hover {
  background-color: #ccc;
}

/* Add an italic font style to all quotes */
q {
  font-size: 5.3vw;
  font-style: italic;
  text-align: left;
}

@media only screen and (min-width: 1000px) {
  .mySlides {
    padding: 0 10rem;
  }
}

.icon-adjustment {
}

@media only screen and (max-width: 1000px) {
  .icon-adjustment {
    margin-left: 3rem;
  }
  .text-adjustment {
    text-align: justify;
    text-justify: inter-word;
    margin-right: 1.5rem;
    /*overflow: hidden;*/
    word-wrap: break-word;
    display: table-row;
    margin-left: 1.5rem;
    overflow-wrap: break-word;
  }
  .level-item {
    flex-shrink: 1;
    margin-right: 1.5rem;
  }
}
@media only screen and (max-width: 768px) {
  /*you do not need icon-adjustment as icon is on the next line*/
  .text-adjustment-highlight {
    margin-left: 2rem;
    text-align: justify;
  }
}

/*for medium screens*/
@media only screen and (min-width: 1000px) and (max-width: 1280px) {
  .icon-adjustment {
    margin-left: 5rem;
  }
  .level-item {
    flex-shrink: 1;
    margin-right: 1.5rem;
  }
}
